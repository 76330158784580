<template>
  <div class="box">
    <div class="search">
      <el-form v-if="!detailShow" label-width="120px" label-height="60px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="店铺信息：" prop="businessStatus">
              <el-input v-model="form.businessName" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="交易时间：" prop="industryType">
              <el-date-picker @change="change" v-model="valuechange" type="daterange" start-placeholder="开始日期"
                end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"/>
            </el-form-item>
          </el-col>
          <el-col :span="8" style="display: flex; justify-content: flex-end">
            <el-button @click="reset" icon="el-icon-refresh-left">重置</el-button>
            <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
          </el-col>
        </el-row>
      </el-form>
      <el-form v-if="detailShow" label-width="120px" label-height="60px">
        <el-row>
          <el-col :span="5">
            <el-form-item label="订单号" prop="id">
              <el-input v-model="form1.id" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="订单状态" prop="userOrderStatus">
              <el-select class="input" v-model="form1.userOrderStatus" clearable>
                <el-option v-for="item in orderStatusOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="下单日期" prop="industryType">
              <el-date-picker @change="change1" v-model="valuechange1" type="daterange" start-placeholder="开始日期"
                end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"/>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="操作员" prop="creatTime">
              <el-input v-model="form1.verificationUserName" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3" style="display: flex; justify-content: flex-end">
            <el-button @click="reset1" icon="el-icon-refresh-left">重置</el-button>
            <el-button type="primary" @click="search1" icon="el-icon-search">搜索</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="finance">
      <div class="fin-box">
        <div class="fin-li">
          <div>门店总营业额</div>
          <div class="fin-li-bott">￥255</div>
        </div>
        <div class="fin-li">
          <div>门店总实收</div>
          <div class="fin-li-bott">￥255</div>
        </div>
        <div class="fin-li">
          <div>已结算优惠券</div>
          <div class="fin-li-bott">￥255</div>
        </div>
        <div class="fin-li">
          <div>已结算积分</div>
          <div class="fin-li-bott">￥255</div>
        </div>
      </div>
      <div style="border-right: 1px solid #000; height: 40px;"></div>
      <div class="fin-box">
        <div class="fin-li">
          <div>平台总入账</div>
          <div class="fin-li-bott">￥255</div>
        </div>
        <div class="fin-li">
          <div>平台总商家抽佣</div>
          <div class="fin-li-bott">￥255</div>
        </div>
        <div class="fin-li">
          <div>平台总配送费抽佣</div>
          <div class="fin-li-bott">￥255</div>
        </div>
      </div>
      <div style="border-right: 1px solid #000; height: 40px;"></div>
      <div class="fin-box">
        <div class="fin-li">
          <div>平台外卖抽佣</div>
          <div class="fin-li-bott">￥255</div>
        </div>
        <div class="fin-li">
          <div>平台团购抽佣</div>
          <div class="fin-li-bott">￥255</div>
        </div>
        <div class="fin-li">
          <div>平台到店扫码抽佣</div>
          <div class="fin-li-bott">￥255</div>
        </div>
      </div>
      <div style="border-right: 1px solid #000; height: 40px;"></div>
      <div class="fin-box">
        <div class="fin-li">
          <div>平台总退款金额</div>
          <div class="fin-li-bott">￥255</div>
        </div>
      </div>
    </div>
    <div class="tab">
      <div class="add" style="display: flex; justify-content: space-between;">
        <div>
          <el-button @click="backList" v-show="detailShow">返回</el-button>
        </div>
        <div style="text-align: right;">
          <el-button type="primary" @click="toExport">导出</el-button>
        </div>
      </div>
      <div v-show="!detailShow">
        <el-table :data="tableData" style="width: 100%" height="calc(100vh - 480px)" border :header-cell-style="{
          'background-color': '#f2f2f2',
          'text-align': 'center',
          border: '1px solid #ddd',
        }">
          <el-table-column type="index" label="序号" width="60" align="center">
          </el-table-column>
          <el-table-column prop="businessName" width="180" show-overflow-tooltip label="商家名称" align="center">
          </el-table-column>
          <el-table-column prop="businessAccount" label="商家账号" width="100" align="center">
          </el-table-column>
          <el-table-column prop="sellNum" label="订单数" align="center">
          </el-table-column>
          <el-table-column prop="totalNum" label="订单总额" align="center">
          </el-table-column>
          <el-table-column prop="payoutsTotalNum" label="商品实收" width="100" align="center">
          </el-table-column>
          <el-table-column prop="takeawayRake" label="平台外卖抽佣" width="150" align="center">
          </el-table-column>
          <el-table-column prop="buyRake" label="平台团购抽佣" align="center">
          </el-table-column>
          <el-table-column prop="yardRake" label="平台到店扫码抽佣" align="center">
          </el-table-column>
          <el-table-column prop="totalNum" label="门店收款金额" align="center">
          </el-table-column>
          <el-table-column prop="businessStatus" label="结算金额" align="center">
          </el-table-column>
          <el-table-column prop="businessStatus" label="门店退款金额" align="center">
            <!-- <template slot-scope="scope">
              <img style="width: 100px;height: 100px;" :src="scope.row.licenceImg" alt="">
            </template> -->
          </el-table-column>
          <el-table-column prop=" " label="操作" v-if="!detailShow" width="160" fixed="right" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="handleDetail(scope.row)">查看明细</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page.sync="queryInfo.currPage" :page-size="queryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </div>
      <div v-show="detailShow">
        <el-table :data="tableData1" style="width: 100%" height="calc(100vh - 480px)" border :header-cell-style="{
          'background-color': '#f2f2f2',
          'text-align': 'center',
          border: '1px solid #ddd',
        }">
          <el-table-column type="index" label="序号" width="60" align="center">
          </el-table-column>
          <el-table-column prop="id" label="订单号" align="center" show-overflow-tooltip min-width="160">
          </el-table-column>
          <el-table-column prop="businessName" width="180" label="店铺名称" show-overflow-tooltip align="center">
          </el-table-column>
          <!-- <el-table-column prop="businessAccount" label="店铺账号" width="100" show-overflow-tooltip align="center">
          </el-table-column> -->
          <el-table-column prop="creatTime" label="下单时间" align="center" width="150">
          </el-table-column>
          <el-table-column prop="commodityType" label="订单类型" align="center">
            <template slot-scope="scope">
              <el-tag type="success">{{ scope.row.commodityType == 1 ? '团购' :
                scope.row.commodityType == 2 ? '外卖' :
                  scope.row.commodityType == 3 ? '积分商品' :
                    scope.row.commodityType == 4 ? '帮我买' : '帮我送' }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="totalNum" label="订单总额" width="120" align="center">
            <!-- buyPrice 订单应收金额 -->
          </el-table-column>
          <el-table-column prop="platform" label="平台抽成比例" width="120" align="center">
          </el-table-column>
          <el-table-column prop="platformNum" label="平台抽成金额" width="120" align="center">
          </el-table-column>
          <el-table-column prop="paidNum" label="订单实收金额" width="120" align="center">
          </el-table-column>
          <el-table-column prop="" label="余额支付" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.totalNum-scope.row.amountWechatNum}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="amountWechatNum" label="微信支付" align="center">
          </el-table-column>
          <el-table-column prop="integral" label="积分抵扣" align="center">
          </el-table-column>
          <el-table-column prop="userCouponsNum" label="优惠券抵扣" width="100" align="center">
          </el-table-column>
          <el-table-column prop="dd" label="满减" align="center">
          </el-table-column>
          <el-table-column prop="freightNum" label="商家承担配送费" width="110" align="center">
          </el-table-column>
          <el-table-column prop="dd" label="自配送到账运费" width="110" align="center">
          </el-table-column>
          <el-table-column prop="packingNum" label="打包费" align="center">
          </el-table-column>
          <el-table-column prop="verificationUserName" label="核销操作员" width="100" align="center">
          </el-table-column>
          <el-table-column prop="userOrderStatus" label="备注" show-overflow-tooltip align="center">
            <template slot-scope="scope">
              <el-tag type="success">{{ scope.row.userOrderStatus == 1 ? '购物车' :
                scope.row.userOrderStatus == 2 ? '待接单' :
                  scope.row.userOrderStatus == 3 ? '进行中' :
                    scope.row.userOrderStatus == 4 ? '待核销' :
                      scope.row.userOrderStatus == 5 ? '已完成' :
                        scope.row.userOrderStatus == 6 ? '已评价' :
                          scope.row.userOrderStatus == 7 ? '售后' :
                            scope.row.userOrderStatus == 8 ? '已退款' :
                              scope.row.userOrderStatus == 9 ? '已取消' : '已删除' }}</el-tag>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination @size-change="handleSizeChange1" @current-change="handleCurrentChange1"
            :current-page.sync="queryInfo1.currPage" :page-size="queryInfo1.pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="total1">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      total: 0,
      queryInfo: {
        pageSize: 10,
        pageCount: 1,
        currPage: 1,
        condition: {
          name: '',
          startDate: "",
          endDate: ""
        }
      },
      businessId: null,
      businessName: null,
      tableData1:[],
      total1: 0,
      queryInfo1: {
        pageSize: 10,
        currPage: 1,
        condition: {
        }
      },
      valuechange: [],
      valuechange1: [],
      form: {},
      form1: {},
      options: [{
        value: '1',
        label: '店长'
      }, {
        value: '2',
        label: '员工'
      }],
      orderStatusOptions: [
        { value: 1, label: '购物车' },
        { value: 2, label: '待接单' },
        { value: 3, label: '进行中' },
        { value: 4, label: '待核销' },
        { value: 5, label: '已完成' },
        { value: 6, label: '已评价' },
        { value: 7, label: '售后' },
        { value: 8, label: '已退款' },
        { value: 9, label: '已取消' },
        { value: 10, label: '已删除' },
      ],
      detailShow: false,
    };
  },
  created() {
    this.getList()
  },
  mounted() {

  },
  methods: {
    change() {
      // console.log('a-valuechange',this.valuechange)
      this.form.startDate = this.valuechange[0]
      this.form.endDate = this.valuechange[1]
    },
    change1() {
      this.form1.startDate = this.valuechange1[0]
      this.form1.endDate = this.valuechange1[1]
    },
    newDate(time) {
      var date = new Date(time)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      var d = date.getDate()
      d = d < 10 ? '0' + d : d
      var h = date.getHours()
      h = h < 10 ? '0' + h : h
      var minute = date.getMinutes()
      minute = minute < 10 ? '0' + minute : minute
      var s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + s
    },
    //获取数据
    getList() {
      var that = this;
      that.$http.post("/business/list", that.queryInfo).then(function (response) {
        if (response.data.code == 200) {
          that.total = response.data.data.count;
          that.queryInfo.currPage = response.data.data.currPage
          that.tableData = response.data.data.data;
        }
      });
    },
    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList()
    },
    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList()
    },
    search() {
      this.queryInfo.condition = this.form
      this.queryInfo.currPage = 1
      this.getList()
    },
    reset() {
      this.form = {}
      this.queryInfo.condition = {}
      this.queryInfo.currPage = 1
      this.valuechange = []
      this.getList()
    },
    // 查看明细
    handleDetail(row) {
      this.detailShow = true
      this.businessName = row.businessName
      this.businessId = row.id
      this.getList1()
    },
    // 返回-列表
    backList() {
      this.detailShow = false
      this.businessName = null
      this.businessId = null
      // this.getList()
    },
    //获取账单数据
    getList1() {
      var that = this;
      // this.queryInfo1.condition.businessName = this.businessName
      this.queryInfo1.condition.businessId = this.businessId
      this.queryInfo1.condition.userOrderKey = 1
      this.queryInfo1.condition.totalOrderType = 2
      that.$http.post("/order/adminQueryOrder", that.queryInfo1).then(function (response) {
        if (response.data.code == 200) {
          that.total1 = response.data.data.count;
          that.queryInfo1.currPage = response.data.data.currPage
          that.tableData1 = response.data.data.data;
        }
      });
    },
    // 修改页数大小
    handleSizeChange1(val) {
      this.queryInfo1.pageSize = val;
      this.getList1()
    },
    // 获取当前页面
    handleCurrentChange1(val) {
      this.queryInfo1.currPage = val;
      this.getList1()
    },
    search1() {
      this.queryInfo1.condition = this.form1
      this.queryInfo1.currPage = 1
      this.getList1()
    },
    reset1() {
      this.form = {}
      this.queryInfo1.condition = {}
      this.queryInfo1.currPage = 1
      this.valuechange1 = []
      this.getList1()
    },
    // 导出
    toExport() {
      const that = this
      const _data = {
        ...that.queryInfo.condition
      }
      this.$http.post('/exportExcel/exportBusiness', _data).then(response => {
        const blob = new Blob([response.data])
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.download = '商家账单'
        document.body.appendChild(link)
        link.click()
      })
    }
  }
};
</script>

<style lang="less" scoped>
.box {
  .search {
    background-color: #fff;
    box-sizing: border-box;
    padding: 20px 20px 10px 20px;

    .search-top {
      width: 1540px;
      height: 66px;
      padding: 2px 2px 2px 2px;
      background-color: rgba(30, 152, 215, 0.14);

      display: flex;
      align-items: center;
      margin-bottom: 50px;
      cursor: pointer;

      div {
        width: 150px;
        text-align: center;
      }

      .active {
        border-bottom: 2px solid red;
      }
    }
  }

  .finance {
    padding: 26px 6px;
    border-radius: 5px;
    background-color: #ffffff;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-top: 20px;
    font-size: 15px;
    justify-content: space-around;

    .fin-box {
      display: flex;

      .fin-li {
        text-align: center;
        font-size: 14px;
        margin-left: 8px;

        .fin-li-bott {
          margin-top: 10px;
        }
      }
    }
  }

  .tab {
    background-color: #fff;
    margin-top: 20px;
    padding: 20px
  }

  .dia {
    .diafour {
      display: flex;
      justify-content: space-between;
      padding: 20px 130px;
    }
  }

  .drawer {
    height: 900px;
    overflow-y: scroll;

    .drauser {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 30px;
      box-sizing: border-box;

      div {
        width: 30%;
        color: #847575;
        margin-bottom: 30px;

        span {
          color: #000;
        }
      }
    }

    .draimg {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 30px;

      .imgbox {
        width: 30%;
        display: flex;

        .imgname {
          color: #000;
        }

        .img {
          margin-top: 40px;
          display: flex;

          img {
            width: 89px;
            height: 65px;
          }
        }

      }
    }

    .bl {
      padding: 0 30px;

      .bl-li {
        display: flex;
        align-items: center;
        margin-top: 15px;

        .bls {
          display: flex;
          align-items: center;
          margin-left: 15px;

          input {
            width: 100px;
            height: 32px;
            text-align: center
          }

          .text-box {
            width: 38px;
            height: 38px;
            background-color: rgba(230, 230, 230, 0.55);
            text-align: center;
            line-height: 38px;
          }
        }
      }
    }

    .qx {
      padding: 0 30px;
      margin-top: 30px;
    }
  }

  .diatwo {
    box-sizing: border-box;
    padding: 0 30px;

    .order1 {
      display: flex;
      margin-bottom: 20px;

      div {
        margin-right: 20px;
      }
    }

    .order2 {
      display: flex;

      .li {
        width: 206px;
        height: 80px;
        padding: 2px 2px 2px 2px;
        border: 1px solid #1e98d7;
        background-color: rgba(30, 152, 215, 0.06);
        box-sizing: border-box;
        color: #1e98d7;
        text-align: center;
        padding-top: 30px;

        margin-right: 20px;
      }

      .li2 {
        width: 206px;
        height: 80px;
        padding: 2px 2px 2px 2px;
        border: 1px solid #1e98d7;
        background-color: rgba(30, 152, 215, 0.06);
        box-sizing: border-box;
        color: #1e98d7;
        align-items: center;


        margin-right: 20px;
        display: flex;

        .li-left {
          color: rgba(251, 142, 0, 0.99);
          margin-left: 10px;
          margin-right: 10px;
        }

        .li-right {
          padding-top: 15px;
        }
      }
    }

    .order3 {
      font-weight: 700;
      color: #333333;
      margin-top: 30px;
    }

    .order4 {
      display: flex;
      flex-wrap: wrap;
      margin-top: 30px;

      div {
        width: 50%;
        margin-top: 10px;
      }
    }

  }

  .diasale {
    color: #333333;
    font-size: 16px;

    .sale1 {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
    }

    .sale2 {
      margin-top: 5px;

      .de2 {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .de-left {
          display: flex;

          img {
            width: 66px;
            height: 68px;
            border-radius: 5px;
          }

          .de-jj {
            margin-left: 5px;

            div {
              height: 33px;
              line-height: 33px;
            }

          }
        }

        .de-right {
          color: #e30606;
        }
      }

      .de3 {
        margin-top: 10px;

        .de-img {
          display: flex;

          img {
            width: 66px;
            height: 68px;
            border-radius: 5px;
            margin-right: 10px;
          }
        }

        border-bottom: 1px solid #e30606;
        padding-bottom: 40px
      }
    }

    .sale3 {
      display: flex;
      justify-content: space-between;
      margin-top: 20px
    }

    .sale4 {
      margin-top: 20px;
      display: flex;

      textarea {
        width: 680px;
        height: 60px;
        padding: 2px 2px 2px 2px;
        border-radius: 5px;
        border: 1px solid rgba(121, 121, 121, 0.26);
      }
    }
  }
}
</style>